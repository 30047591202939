<template>
  <qtm-content-block v-if="!readonly || po?.po_number" collapsible title="Link To PO">
    <div>
      Here you can link invoice to the existing PO.
      <v-tooltip location="top" max-width="416">
        <template v-slot:activator="{ props: activator }">
          <v-icon v-bind="activator" class="ml-2" color="mid-grey" icon="mdi-information-outline" size="small" />
        </template>
        By linking invoice to existing PO you can easily match invoice items to the items listed in the PO
        and assure better control over spending and budgeting.
      </v-tooltip>
    </div>
    <qtm-dialog-card v-model="open" title="Link Invoice to PO" width="474">
      <template v-slot:activator="{ props: activator }">
        <div v-if="po" class="d-flex align-center mt-4 qtm-body">
          Linked to {{ po.po_number }}
          <qtm-icon-btn v-if="!readonly" v-bind="activator" class="ml-4" icon="mdi-pencil" size="small" />
        </div>
        <qtm-btn v-else v-bind="activator" class="mt-4" secondary>
          Link to PO
        </qtm-btn>
      </template>
      <qtm-input-label class="mt-6" label="Purchase Order">
        <order-search
          autofocus
          :filters="{ date_cancelled__exists: false, has_purchase_order: true }"
          :model-value="null"
          @update:model-value="fetchOrder"
        >
          <template v-slot:item="{ item, props: itemProps }">
            <order-document-item
              v-bind="itemProps"
              :company-logo="null"
              :document="item.raw"
              no-action
              :title="item.raw.reference_name"
              :user="null"
            />
          </template>
        </order-search>
      </qtm-input-label>
      <div v-if="localOrder && localPo" class="bg-white pa-4 mt-6">
        <qtm-input-label label="Purchase Order">
          <div class="d-flex justify-space-between">
            <div v-text="localPo.po_number" />
            <qtm-btn class="mr-n2" prepend-icon="mdi-link-off" tertiary @click="localOrder = null">
              Unlink
            </qtm-btn>
          </div>
        </qtm-input-label>
        <v-divider class="mx-n4 my-4" />
        <qtm-input-label label="Order Info">
          <div v-text="localOrder.reference_name" />
          <div>
            {{ localOrder.jobsite?.project_number }} - {{ localOrder.jobsite?.name }}
          </div>
        </qtm-input-label>
        <qtm-input-label class="mt-4" label="Supplier">
          <div v-text="localPo.supplier_name" />
          <div v-text="localPo.supplier_address" />
          <qtm-address
            :city="localPo.supplier_city"
            :postal-code="localPo.supplier_postal_code"
            :province="localPo.supplier_province"
          />
        </qtm-input-label>
        <qtm-input-label v-if="localPo.supplier_contact" class="mt-4" label="Supplier Contact">
          <div>
            {{ localPo.supplier_contact_first_name }} {{ localPo.supplier_contact_last_name }}
          </div>
          <div v-text="localPo.supplier_contact_phone" />
          <div v-text="localPo.supplier_contact_email" />
        </qtm-input-label>
      </div>
      <template v-slot:actions>
        <v-spacer />
        <qtm-btn :loading="loading" size="x-large" tertiary @click="open = false">
          Cancel
        </qtm-btn>
        <qtm-btn :loading="loading" size="x-large" @click="save">
          Save
        </qtm-btn>
      </template>
    </qtm-dialog-card>
  </qtm-content-block>
</template>

<script setup lang="ts">
import type { Order } from '@quotetome/materials-api'
import OrderDocumentItem from '@/components/search/order-document-item.vue'
import OrderSearch from '@/components/search/order-search.vue'

export interface Props {
  order: Order | null
  readonly?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['update:contact', 'update:dialog-open', 'update:order', 'update:project', 'update:supplier'])

const open = ref(false)
const localOrder = ref<Order | null>(null)

watch(open, value => {
  if (value) {
    localOrder.value = props.order
  }
  emit('update:dialog-open', value)
})

const localPo = computed(() => localOrder.value?.pos[0])
const po = computed(() => props.order?.pos[0])

const loading = ref(false)
const { $api, $error } = useNuxtApp()
const fetchOrder = async ({ id }: { id: number }) => {
  loading.value = true
  try {
    const order = await $api.v1.rfqs.get(id)

    localOrder.value = order
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}
const save = () => {
  emit('update:order', localOrder.value)

  if (localOrder.value) {
    emit('update:project', localOrder.value.jobsite)
  }

  if (localPo.value) {
    const SUPPLIER_FIELDS = ['address', 'city', 'name', 'postal_code', 'province']
    const supplier: any = { id: localPo.value.supplier }

    SUPPLIER_FIELDS.forEach((field) => {
      supplier[field] = localPo.value?.[`supplier_${field}` as any]
    })

    emit('update:supplier', supplier)

    if (localPo.value.supplier_contact) {
      const CONTACT_FIELDS = ['email', 'first_name', 'last_name', 'phone']
      const contact: any = { id: localPo.value.supplier_contact }

      CONTACT_FIELDS.forEach((field) => {
        contact[field] = localPo.value?.[`supplier_contact_${field}` as any]
      })

      emit('update:contact', contact)
    }
    else {
      emit('update:contact', null)
    }
  }

  open.value = false
}
</script>
