<template>
  <search-item
    v-bind="action"
    :company-logo="document.company_logo"
    :project-number="document.project_number"
    :reference="reference"
    :supplier="supplier"
    :title="document.reference_name"
    :user="owner"
    @click="$emit('click')"
  />
</template>

<script setup>
import { computed } from 'vue'
import SearchItem from '@/components/search/search-item.vue'

defineEmits(['click'])

const props = defineProps({
  document: {
    type: Object,
    required: true,
  },
  noAction: {
    type: Boolean,
    default: false,
  },
})

const owner = computed(() => {
  const name = props.document.owner.split(' ')

  return {
    id: props.document.owner_id,
    email: props.document.owner_email,
    first_name: name[0] || '',
    last_name: name[1] || '',
  }
})
const reference = computed(() => {
  if (props.document.po_number) {
    return `${props.document.qtm_reference_number} | ${props.document.po_number}`
  }

  return props.document.qtm_reference_number
})
const supplier = computed(() => {
  if (props.document.supplier_name) {
    return { name: props.document.supplier_name, logo: props.document.supplier_logo }
  }

  return undefined
})

const adminStore = useAdminStore()
const authStore = useAuthStore()
const action = computed(() => {
  if (props.noAction) {
    return undefined
  }

  return authStore.isStaff
    ? { onClick: () => adminStore.activateOrder({ id: props.document.id }) }
    : { to: { name: 'orders-id', params: { id: props.document.id } } }
})
</script>
